div.invalid, input.invalid {
    border: 2px solid red;
}

span.warning {
    color: red;
}

tr.details-row {
    font-weight:bold;
}

a.myclickable {
    cursor: pointer;
}